import { login, register, getUser } from '@/api/user'
import storage from '@/utils/storage'
import { TOKEN, USER_INFO } from '@/config'
import { useMessage } from 'naive-ui'

import router from '@/router'

export default {
  namespaced: true,
  state: () => ({
    token: storage.getItem(TOKEN) || '',
    userInfo: {}
  }),
  mutations: {
    setToken(state, token) {
      state.token = token
      storage.setItem(TOKEN, token)
    },
    setUserInfo(state, userInfo) {
      state.userInfo = userInfo
    }
  },
  actions: {
    login({ commit }, userInfo) {
      const { mobile, password, captcha } = userInfo
      return new Promise((resolve, reject) => {
        login({
          mobile,
          password,
          captcha
        })
          .then((result) => {
            resolve(result)
            commit('setToken', result.data.token)
            commit('setUserInfo', result.data.userInfo)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    register({ commit }, userInfo) {
      const { mobile, password, code } = userInfo
      return new Promise((resolve, reject) => {
        register({
          mobile,
          password,
          code
        })
          .then((result) => {
            resolve(result)
            commit('setToken', result.data.token)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    async getUserInfo({ commit }) {
      const { data } = await getUser()
      commit('setUserInfo', data)
    },

    logout({ commit }) {
      router.push('/')
      commit('setToken', '')
      commit('setUserInfo', {})
      storage.clearAll()
    }
  }
}
