import axios from 'axios'
import store from '@/store'
import router from '@/router'
import { useMessage } from 'naive-ui/es/message'

const message = useMessage()
const request = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 5000
})

// 请求拦截器
request.interceptors.request.use((config) => {
  // 判断token是否存在
  if (store.getters.token) {
    // 如果token存在 请求的时候带上token
    config.headers.token = store.getters.token
  }
  // 必须返回 config
  return config
})

// 响应拦截
request.interceptors.response.use(
  (res) => {
    const { status, data } = res
    if (status === 200) {
      return Promise.resolve(data)
    }
  },
  (err) => {
    if (err.response.status === 401) {
      store.dispatch('user/logout')
    }
    return Promise.reject(err.response)
  }
)

export default request
