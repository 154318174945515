import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'

const routes = [
  {
    path: '/',
    name: '首页',
    component: () => import('@/views/index')
  },
  {
    path: '/course/detail/:id',
    name: '课程详情',
    component: () => import('@/views/course/detail')
  },
  {
    path: '/course/video/:id',
    name: '视频播放',
    component: () => import('@/views/course/courseVideo')
  },
  {
    path: '/course',
    name: '课程',
    component: () => import('@/views/course/index')
  },
  {
    path: '/project/detail/:id',
    name: '项目详情',
    component: () => import('@/views/project/detail')
  },
  {
    path: '/project/document/:id',
    name: '文档阅读',
    component: () => import('@/views/project/document')
  },
  {
    path: '/project',
    name: '项目训练营',
    component: () => import('@/views/project/index')
  },
  {
    path: '/systemcourse',
    name: '体系课',
    component: () => import('@/views/systemcourse/index')
  },

  {
    path: '/systemcourse/detail/:id',
    name: '体系课详情',
    component: () => import('@/views/systemcourse/detail')
  },
  {
    path: '/systemcourse/projectdetail/:id',
    name: '体系课项目详情',
    component: () => import('@/views/systemcourse/projectDetail')
  },
  {
    path: '/systemcourse/document/:id',
    name: '体系课文档',
    component: () => import('@/views/systemcourse/document')
  },
  {
    path: '/vip',
    name: 'VIP会员',
    component: () => import('@/views/vip')
  },

  {
    path: '/learnpath/detail/:id',
    name: '路线详情',
    component: () => import('@/views/learnpath/detail')
  },
  {
    path: '/learnpath',
    name: '学习路线',
    component: () => import('@/views/learnpath/index')
  },
  {
    path: '/user',
    name: '个人中心',
    redirect: '/user/course',
    component: () => import('@/views/user/index'),
    children: [
      {
        path: '/user/course',
        name: '我的课程',
        component: () => import('@/views/user/course')
      },
      {
        path: '/user/record',
        name: '学习记录',
        component: () => import('@/views/user/record')
      },
      {
        path: '/user/ranking',
        name: '学习英雄榜',
        component: () => import('@/views/user/ranking')
      },
      {
        path: '/user/info',
        name: '个人信息',
        component: () => import('@/views/user/info')
      }
    ]
  },
  {
    name: '404',
    path: '/404',
    component: () => import('@/views/notFound.vue')
  },
  {
    path: '/:catchAll(.*)', // 此处需特别注意至于最底部
    redirect: '/404'
  }
]

const router = createRouter({
  // history: createWebHashHistory(),
  history: createWebHistory(),
  routes
})

router.beforeEach(async (to, from, next) => {
  if (store.getters.token) {
    // 已经成功登录
    if (!store.getters.hasUserInfo) {
      // 如果没有userInfo 就去获取
      await store.dispatch('user/getUserInfo')
    }
  }
  let blacklist = [
    '/user',
    '/user/profile',
    '/user/info',
    '/user/course',
    '/user/ranking',
    '/user/record'
  ]
  if (blacklist.indexOf(to.path) > -1) {
    if (store.getters.token) {
      //如果有token就进入
      next()
    } else {
      next('/')
    }
  } else {
    next()
  }
})

export default router
