import request from '@/utils/request'

// 登录的请求接口
export const login = (data) => {
  return request({
    url: '/user/login',
    method: 'POST',
    data
  })
}

// 登录的请求接口
export const register = (data) => {
  return request({
    url: '/user/register',
    method: 'POST',
    data
  })
}

// 获取验证码接口
export const getCaptcha = (data) => {
  return request({
    url: '/captcha',
    method: 'GET',
    data
  })
}

// 获取用户信息
export const getUser = () => {
  return request({
    url: '/user/info',
    method: 'GET'
  })
}

// 加入课程
export const addCoruse = (data) => {
  return request({
    url: '/user/course',
    method: 'POST',
    data
  })
}

// 判断是否已经加入该课程
export const hasCourse = (data) => {
  return request({
    url:
      '/user/course/hascourse?user_id=' +
      data.user_id +
      '&course_id=' +
      data.course_id,
    method: 'GET'
  })
}

// 添加学习记录
export const addUserCourseRecord = (data) => {
  return request({
    url: '/user/course/record',
    method: 'POST',
    data
  })
}

// 获取学习记录
export const getUserCourseRecord = (data) => {
  let url
  if (data) {
    url = '/user/course/record?'
    for (let k in data) {
      url += `${k}=${data[k]}&`
    }
    url = url.slice(0, -1)
  } else {
    url = '/user/course/record'
  }
  return request({
    url: url,
    method: 'GET'
  })
}

// 获取所有加入过的课程
export const getUserCourse = (data) => {
  let url
  if (data) {
    url = '/user/course?'
    for (let k in data) {
      url += `${k}=${data[k]}&`
    }
    url = url.slice(0, -1)
  } else {
    url = '/user/course'
  }
  return request({
    url: url,
    method: 'GET'
  })
}

// 增加经验值
export const addExperience = (data) => {
  return request({
    url: '/user/experience',
    method: 'PUT',
    data
  })
}
// 获取经验值
export const getExperiencRanking = () => {
  return request({
    url: '/user/experience',
    method: 'GET'
  })
}

// 修改绑定手机
export const updateMobile = (data) => {
  return request({
    url: '/user/mobile',
    method: 'PUT',
    data
  })
}

// 修改密码
export const updatePass = (data) => {
  return request({
    url: '/user/password',
    method: 'PUT',
    data
  })
}

//修改用户名
export const updateUserName = (data) => {
  return request({
    url: '/user/username',
    method: 'PUT',
    data
  })
}

//修改头像
export const updateAvatar = (data) => {
  return request({
    url: '/user/avatar',
    method: 'PUT',
    data
  })
}

// 找回密码
export const resetPass = (data) => {
  return request({
    url: '/user/password/reset',
    method: 'PUT',
    data
  })
}

// 判断是否已经加入该课程
export const hasProject = (data) => {
  return request({
    url:
      '/user/project/hasproject?user_id=' +
      data.user_id +
      '&project_id=' +
      data.project_id,
    method: 'GET'
  })
}
// 判断是否已经加入该课程
export const hasSystem = (data) => {
  return request({
    url:
      '/user/system/hassystem?user_id=' +
      data.user_id +
      '&system_id=' +
      data.system_id,
    method: 'GET'
  })
}

// 获取所有加入过的课程
export const getUserProject = (data) => {
  let url
  if (data) {
    url = '/user/project?'
    for (let k in data) {
      url += `${k}=${data[k]}&`
    }
    url = url.slice(0, -1)
  } else {
    url = '/user/project'
  }
  return request({
    url: url,
    method: 'GET'
  })
}
