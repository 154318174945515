import storage from '@/utils/storage'
import { USER_INFO } from '@/config'
const getters = {
  token: (state) => state.user.token,
  hasUserInfo: (state) => {
    return (
      JSON.stringify(state.user.userInfo) !== '{}' ||
      Boolean(storage.getItem(USER_INFO))
    )
  },
  userInfo: (state) => state.user.userInfo,
  isVip: (state) => {
    if (JSON.stringify(state.user.userInfo) === '{}') return false
    const userInfo = state.user.userInfo
    if (!userInfo.vip_expired) return false
    const currentTime = new Date().getTime()
    const expired = new Date(userInfo.vip_expired).getTime()
    return expired > currentTime ? true : false
  }
}
export default getters
