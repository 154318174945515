import { createApp } from 'vue'
import './assets/css/reset.css'
import './assets/font/iconfont.css'
import './assets/css/main.css'
import VMdPreview from '@kangc/v-md-editor/lib/preview'
import '@kangc/v-md-editor/lib/style/preview.css'
import vuepressTheme from '@kangc/v-md-editor/lib/theme/vuepress.js'
import '@kangc/v-md-editor/lib/theme/style/vuepress.css'
import createCopyCodePlugin from '@kangc/v-md-editor/lib/plugins/copy-code/index'
import '@kangc/v-md-editor/lib/plugins/copy-code/copy-code.css'

import Prism from 'prismjs'
VMdPreview.use(vuepressTheme, {
  Prism
}).use(createCopyCodePlugin())

import App from './App.vue'
import router from './router'
import store from './store'

createApp(App).use(store).use(router).use(VMdPreview).mount('#app')
